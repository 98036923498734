// import Vue from 'vue'
import Vuex from 'vuex'
import helpers from './modules/helpers';
import user from './modules/user';
import posts from './modules/posts';
import modification from './modules/modification';
import navbar from './modules/navbar';
import category from './modules/category';
import socio from './modules/socio';

export default new Vuex.Store({
    modules: {
        helpers,
        user,
        posts,
        modification,
        navbar,
        category,
        socio
    }
})
