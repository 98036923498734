import SocioService from '../../services/SocioService'

const state = () => ({
    socio: null,
    dadoComercial: null,
    dadosComerciais: [],
    loadingComercial: false,
    total: 0,
    pages: 0,
    page: 1,
    showModalSocio: false,
    datalistEstados: 
    [
        { "sigla": "AC", "nome": "Acre" },
        { "sigla": "AL", "nome": "Alagoas" },
        { "sigla": "AP", "nome": "Amapá" },
        { "sigla": "AM", "nome": "Amazonas" },
        { "sigla": "BA", "nome": "Bahia" },
        { "sigla": "CE", "nome": "Ceará" },
        { "sigla": "DF", "nome": "Distrito Federal" },
        { "sigla": "ES", "nome": "Espírito Santo" },
        { "sigla": "GO", "nome": "Goiás" },
        { "sigla": "MA", "nome": "Maranhão" },
        { "sigla": "MT", "nome": "Mato Grosso" },
        { "sigla": "MS", "nome": "Mato Grosso do Sul" },
        { "sigla": "MG", "nome": "Minas Gerais" },
        { "sigla": "PA", "nome": "Pará" },
        { "sigla": "PB", "nome": "Paraíba" },
        { "sigla": "PR", "nome": "Paraná" },
        { "sigla": "PE", "nome": "Pernambuco" },
        { "sigla": "PI", "nome": "Piauí" },
        { "sigla": "RJ", "nome": "Rio de Janeiro" },
        { "sigla": "RN", "nome": "Rio Grande do Norte" },
        { "sigla": "RS", "nome": "Rio Grande do Sul" },
        { "sigla": "RO", "nome": "Rondônia" },
        { "sigla": "RR", "nome": "Roraima" },
        { "sigla": "SC", "nome": "Santa Catarina" },
        { "sigla": "SP", "nome": "São Paulo" },
        { "sigla": "SE", "nome": "Sergipe" },
        { "sigla": "TO", "nome": "Tocantins" }
    ]
})
  
const mutations = {
    SET_SOCIO: (state, payload) => {
        state.socio = payload;
    },
    SET_DADO_COMERCIAL: (state, payload) => {
        state.dadoComercial = payload;
    },
    SET_DADOS_COMERCIAIS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.dadosComerciais = payload.items
        }else{
            state.dadosComerciais = [...state.dadosComerciais, ...payload.items]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total
    },
    SET_LOADING_COMERCIAL: (state, payload) => {
        state.loadingComercial = payload
    },
    SET_SHOW_MODAL_SOCIO: (state, payload) => {
        state.showModalSocio = payload
    },
}

const actions = {
    async fetchDadosComerciais({commit}, { keyword = '', pageNumber = 1}){
        try {
            commit('SET_LOADING_COMERCIAL', true);
            const response = await SocioService.get(keyword, pageNumber );
            commit('SET_SOCIO', response.data.Socio);
            commit('SET_DADOS_COMERCIAIS', response.data);
            commit('SET_LOADING_COMERCIAL', false);
        } catch (error) {
            commit('SET_LOADING_COMERCIAL', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updateStatusDadosComerciais({commit, state}, check_adm){
        try {
            if(state.loadingComercial || state.dadoComercial.check_adm == check_adm) return
            commit('SET_LOADING_COMERCIAL', true);
            const response = await SocioService.updateAll({
                id: state.dadoComercial.id,
                check_adm
            });
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_COMERCIAL', false);
        } catch (error) {
            commit('SET_LOADING_COMERCIAL', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updateDadosComerciais({commit, state}){
        try {
            commit('SET_LOADING_COMERCIAL', true);
            const response = await SocioService.updateAll(state.dadoComercial);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_COMERCIAL', false);
        } catch (error) {
            commit('SET_LOADING_COMERCIAL', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getSocio: state => state.socio,
    getDadoComercial: state => state.dadoComercial,
    getDadosComerciais: state => state.dadosComerciais,
    getLoadingComercial: state => state.loadingComercial,
    getShowModalSocio: state => state.showModalSocio,
    getDatalistEstados: state => state.datalistEstados
}

export default {
    state,
    getters,
    mutations,
    actions
};