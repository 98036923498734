import ModificationService from '../../services/ModificationService'

const state = () => ({
    loadingModifications: false,
    modification: null,
    modifications: [],
    total: 0,
    pages: 0,
    page: 1,
    totalModifications: null,
    totalModificationsPendentes: null,
    showModalModifications: false,
})
  
const mutations = {
    SET_MODIFICATION: (state, payload) => {
        state.modification = payload
    },
    SET_MODIFICATIONS: (state, payload) => {
        state.modifications = payload.itens
        state.page =  payload.pageNumber
        state.pages =  payload.pages
    },
    SET_MORE_MODIFICATIONS(state, payload) {
        state.modifications = [...state.modifications, ...payload.itens]
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total = payload.total
    },
    SET_TOTAL_MODIFICATIONS: (state, payload) => {
        state.totalModifications = payload
    },
    SET_TOTAL_PENDENTES_MODIFICATIONS: (state, payload) => {
        state.totalModificationsPendentes = payload.pendingLogsCount
    },
    SET_SHOW_MODAL_MODIFICATIONS: (state, payload) => {
        state.showModalModifications = payload
    },
    SET_LOADING_MODIFICATIONS: (state, payload) => {
        state.loadingModifications = payload
    }
}

const actions = {
    async fetchTotalModifications({commit}){
        try{
            const response = await ModificationService.getTotal();
            commit('SET_TOTAL_MODIFICATIONS', response.data)
        } catch (error) {
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchTotalPendentesModifications({commit}){
        try{
            commit('SET_LOADING_MODIFICATIONS', true)
            const response = await ModificationService.getTotalPendentes();
            commit('SET_TOTAL_PENDENTES_MODIFICATIONS', response.data)
            commit('SET_LOADING_MODIFICATIONS', false)
        } catch (error) {
            commit('SET_LOADING_MODIFICATIONS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchModifications({commit}, { keyword = '', pageNumber = 1, sortBy = 'mais recentes' }){
        try{
            commit('SET_LOADING_MODIFICATIONS', true)
            const response = await ModificationService.get(keyword, pageNumber, sortBy);
            commit('SET_MODIFICATIONS', response.data)
            commit('SET_LOADING_MODIFICATIONS', false)
        } catch (error) {
            commit('SET_LOADING_MODIFICATIONS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchMoreModifications({commit}, { keyword = '', pageNumber, sortBy = 'mais recentes'} ){
        try{
            commit('SET_LOADING_MODIFICATIONS', true)
            const response = await ModificationService.get(keyword, pageNumber, sortBy);
            commit('SET_MORE_MODIFICATIONS', response.data)
            commit('SET_LOADING_MODIFICATIONS', false)
        } catch (error) {
            commit('SET_LOADING_MODIFICATIONS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updataStatusModification({commit}, modification){
        try{
            commit('SET_LOADING_MODIFICATIONS', true)
            await ModificationService.updateStatus(modification);
            commit('SET_LOADING_MODIFICATIONS', false)
        } catch (error) {
            commit('SET_LOADING_MODIFICATIONS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    }
}

const getters = {
    getModifications: state => state.modifications,
    getModification: state => state.modification,
    getTotal: state => state.total,
    getLoadingModifications: state => state.loadingModifications,
    getShowModalModifications: state => state.showModalModifications,
    getTotalModifications: state => state.totalModifications,
    getTotalModificationsPendentes: state => state.totalModificationsPendentes
}

export default {
    state,
    getters,
    mutations,
    actions
};