import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/auth',
    name: 'Authentication',
    component: () => import('@/views/LoginView.vue')
  },
  {
    path: '/users',
    name: 'Usuários',
    component: () => import('@/views/List_Users.vue')
  },
  {
    path: '/modificacoes',
    name: 'Modificações',
    component: () => import('@/views/List_Modificacoes.vue')
  },
  {
    path: '/comerciais',
    name: 'Dados Comerciais',
    component: () => import('@/views/List_Dados_Comerciais.vue')
  },
  {
    path: '/navbar',
    name: 'Navbar',
    component: () => import('@/views/List_Navbars.vue')
  },
  {
    path: '/categories',
    name: 'Categorias',
    component: () => import('@/views/List_Categories.vue')
  },
  {
    path: '/posts',
    name: 'Posts',
    component: () => import('@/views/List_Posts.vue')
  },
  {
    path: '/profile/account',
    name: 'Profile',
    component: () => import('@/views/ProfileView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Error 404',
    component: () => import('@/views/Error404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
