<template>
    <main>
        <div id="app" class="flex h-screen overflow-y-hidden bg-white">
            <SideNav v-if="$store.state.user.user"
                x-transition:enter="transition transform duration-300" 
                x-transition:enter-start="-translate-x-full opacity-30  ease-in" 
                x-transition:enter-end="translate-x-0 opacity-100 ease-out" 
                x-transition:leave="transition transform duration-300"
                x-transition:leave-start="translate-x-0 opacity-100 ease-out" 
                x-transition:leave-end="-translate-x-full opacity-0 ease-in" 
                class="fixed inset-y-0 z-10 flex flex-col flex-shrink-0 w-64 max-h-screen overflow-hidden transition-all transform bg-white border-r shadow-lg lg:z-auto lg:static lg:shadow-none" :class="{'-translate-x-full lg:translate-x-0 lg:w-20': !getshowSideNav}"/>
            <div :class="getAlerts.length > 0 && 'space-y-1 py-1 __alert shadow-sm'" class="fixed top-0 w-full" style="z-index: 999;">
                <AlertComponent v-for="(alert, index) in getAlerts" :key="index" :alert="alert"/>
            </div>
            <router-view class="flex flex-col flex-1 h-full overflow-hidden"/>
        </div>
        <FooterComponent class="flex items-center justify-between flex-shrink-0 bottom-0 p-4 border-t max-h-14" />
    </main>
</template>

<script>
import { mapGetters } from 'vuex'
import FooterComponent from './components/FooterComponent'
import AlertComponent from './components/helper/AlertComponent.vue'
import SideNav from '@/components/sidebar/Sidebar.vue'

export default {
    components: {
        FooterComponent,
        AlertComponent,
        SideNav
    },
    watch: {
        '$store.state.helpers.hideScrollBar': function(){
            if(this.$store.state.helpers.isMenuOpen){
                document.documentElement.style.overflow = 'hidden'
            }else{
                document.documentElement.style.overflow = null
            }
        }
    },
    computed: {
        ...mapGetters(['getAlerts', 'getshowSideNav']),
    }
}
</script>

<style lang="scss">

  html {
      font-family:
          Roboto,
          'Source Sans Pro',
          -apple-system,
          BlinkMacSystemFont,
          'Segoe UI',
          'Helvetica Neue',
          Arial,
          sans-serif;
      font-size: 16px;
      word-spacing: 1px;
      -ms-text-size-adjust: 100%;
      -webkit-text-size-adjust: 100%;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      box-sizing: border-box;
      background-color: white;
  }

  *,
  *::before,
  *::after {
      box-sizing: border-box;
      margin: 0;
  }

  .__alert {
      backdrop-filter: blur(10px);
      background-color: rgba(0, 195, 255, 0.041);
  }

  button {
    cursor: pointer;
  }

  ul {
    list-style: none;
    padding-left: 0px;
  }

</style>