import Api from './Api'

export default {
    get(keyword, pageNumber) {
        return Api().get(`navbar/adm?keyword=${keyword}&pageNumber=${pageNumber}`);
    },
    update(id) {
        return Api().put(`navbar/update/${id}`);
    },
    updateAll() {
        return Api().put(`navbar/updateAll`);
    },
    delete(id) {
        return Api().delete(`navbar/delete/${id}`);
    }
}