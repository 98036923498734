import axios from 'axios'

export default () => {
    const apiUrl = process.env.VUE_APP_NODE_ENV == 'production' ? process.env.VUE_APP_URL_SERVER : process.env.VUE_APP_URL_LOCAL_SERVER;

    return axios.create({
        baseURL: `${apiUrl}/api/`,
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
        }   
    })
}