import Api from './Api'

export default {
    get(keyword, pageNumber) {
        return Api().get(`categories/adm?keyword=${keyword}&pageNumber=${pageNumber}`);
    },
    getOne(slug) {
        return Api().get(`categories/${slug}`);
    },
    updateAll() {
        return Api().put(`categories/updateAll`);
    }
}