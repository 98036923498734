import Api from './Api'

export default {
    get(keyword, pageNumber, sort, category) {
        return Api().get(`posts?keyword=${keyword}&pageNumber=${pageNumber}&sort=${sort}&category=${category}`);
    },
    getOne(slug) {
        return Api().get(`posts/${slug}`);
    },
    updateAll() {
        return Api().put(`posts/updateAll`);
    }
}