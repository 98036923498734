<template>
    <div class="flex justify-between">
        <div>
            <div @click="SET_SHOW_SIDE_NAV(!getshowSideNav)" class="flex items-center justify-between flex-shrink-0" >
                <span class="px-3 mt-2 py-1 text-xl font-semibold leading-8 tracking-wider uppercase whitespace-nowrap">
                    <img alt="Logo" :src="logo_completo" class="h-10 object-cover">
                </span>
            </div>
            <div class="flex-1 overflow-hidden hover:overflow-y-auto border-t border-gray-100">
                <div class="px-2">
                    <div class="p-2 overflow-hidden">
                        <router-link to="/" class="flex items-center p-2 space-x-2 rounded-md hover:bg-gray-100 gap-2" :class="{'justify-center': !getshowSideNav}">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 opacity-75" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" /><path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
                            <span :class="{ 'lg:hidden': !getshowSideNav }" class="">Dashboard</span>
                        </router-link>
                    </div>
                    <ul class="space-y-1 border-t border-gray-100 pt-4 p-2">
                        <li>
                            <router-link to="/users" class="flex items-center p-2 space-x-2 rounded-md hover:bg-gray-100 gap-2" :class="{'justify-center': !getshowSideNav}">
                                <span class="relative flex items-center">
                                    <span v-if="getTotalUsersPendentes">
                                        <div class="absolute -right-2 -top-1 p-1 bg-red-400 rounded-full animate-ping"></div>
                                        <div class="absolute -right-2 -top-1 p-1 bg-red-400 border rounded-full"></div>
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 opacity-75" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" /></svg>
                                </span>
                                <span :class="{ 'lg:hidden': !getshowSideNav }" class="">Novos Usuários</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/comerciais" class="flex items-center p-2 space-x-2 rounded-md hover:bg-gray-100 gap-2" :class="{'justify-center': !getshowSideNav}">
                                <span class="relative flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 opacity-75" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" /></svg>
                                </span>
                                <span :class="{ 'lg:hidden': !getshowSideNav }" class="">Dados Comerciais</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/modificacoes" class="flex items-center p-2 space-x-2 rounded-md hover:bg-gray-100 gap-2" :class="{'justify-center': !getshowSideNav}">
                                <span class="relative flex items-center">
                                    <span v-if="getTotalModificationsPendentes">
                                        <div class="absolute -right-2 -top-1 p-1 bg-red-400 rounded-full animate-ping"></div>
                                        <div class="absolute -right-2 -top-1 p-1 bg-red-400 border rounded-full"></div>
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 opacity-75" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" /></svg>
                                </span>
                                <span :class="{ 'lg:hidden': !getshowSideNav }" class="">Modificações</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/navbar" class="flex items-center p-2 space-x-2 rounded-md hover:bg-gray-100 gap-2" :class="{'justify-center': !getshowSideNav}">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 opacity-75" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z" /></svg>
                                <span :class="{ 'lg:hidden': !getshowSideNav }" class="">NavBar</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/categories" class="flex items-center p-2 space-x-2 rounded-md hover:bg-gray-100 gap-2" :class="{'justify-center': !getshowSideNav}">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 opacity-75" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3" /></svg>
                                <span :class="{ 'lg:hidden': !getshowSideNav }" class="">Categorias</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/posts" class="flex items-center p-2 space-x-2 rounded-md hover:bg-gray-100 gap-2" :class="{'justify-center': !getshowSideNav}">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 opacity-75" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" /></svg>
                                <span :class="{ 'lg:hidden': !getshowSideNav }" class="">Posts</span>
                            </router-link>
                        </li>
                       
                    </ul>
                </div>
            </div>
        </div>

        <div class="sticky inset-x-0 bottom-0 border-t border-gray-100 bg-white p-2">
            <button @click="LOG_USER_OUT()" type="button" class="group relative flex w-full justify-center rounded-lg border px-2 py-1.5 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 opacity-75" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" /></svg>
                <span :class="{'lg:hidden': !getshowSideNav}" class="ml-3"> Sair </span>
            </button>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';
    import logo_completo from '@/assets/logo-escuro.png';

    export default {
        name: "SideNavComponnent",
        components: {},
        data() {
            return {
                logo_completo: logo_completo
            }
        },
        methods: {
            ...mapMutations(['SET_SHOW_SIDE_NAV', 'LOG_USER_OUT'])
        },
        computed: {
            ...mapGetters(['getshowSideNav', 'getTotalModificationsPendentes', 'getTotalUsersPendentes'])
        }
    }
</script>
<style scoped>
    button {
        background: transparent;
    }
</style>