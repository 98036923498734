import NavBarService from '../../services/NavBarService'

const state = () => ({
    itemNavbar: null,
    itensNavbar: [],
    navbarLoading: false,
    total: 0,
    pages: 0,
    page: 1,
    loadingUpdateNavbar: false
})
  
const mutations = {
    SET_ITEM_NAVBAR: (state, payload) => {
        state.itemNavbar =  payload
    },
    SET_ITENS_NAVBAR: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.itensNavbar = payload.items
        }else{
            state.itensNavbar = [...state.itensNavbar, ...payload.items]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total
    },
    SET_NAVBAR_LOADING: (state, payload) => {
        state.navbarLoading = payload;
    },
    SET_NAVBAR_LOADING_UPDATE: (state, payload) => {
        state.loadingUpdateNavbar = payload;
    },
    SET_UPDATE_NAVBAR_ITEM(state, updatedItem) {
        const index = state.itensNavbar.findIndex(item => item.id === updatedItem.id);
    
        if (index !== -1) {
            state.itensNavbar.splice(index, 1, updatedItem);
        } else {
            state.itensNavbar.push(updatedItem);
        }
    },
    DELETE_NAVBAR_ITEM(state, itemId) {
        const index = state.itensNavbar.findIndex(item => item.id === itemId);

        if (index !== -1) {
            state.itensNavbar.splice(index, 1);
        }
    }
}

const actions = {
  
    async fetchItensNavbar({commit}, { keyword = '', pageNumber = 1}){
        try {
            commit('SET_NAVBAR_LOADING', true);
            const response = await NavBarService.get(keyword, pageNumber );
            commit('SET_ITENS_NAVBAR', response.data);
            commit('SET_NAVBAR_LOADING', false);
        } catch (error) {
            commit('SET_NAVBAR_LOADING', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updateNavbar({commit}, id){
        try {
            if(state.loadingUpdateNavbar) return
            commit('SET_NAVBAR_LOADING', true);
            const response = await NavBarService.update(id);
            commit('SET_UPDATE_NAVBAR_ITEM', response.data.item);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_NAVBAR_LOADING', false);
        } catch (error) {
            commit('SET_NAVBAR_LOADING', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updateAllNavbar({commit, dispatch, state}){
        try {
            if(state.loadingUpdateNavbar) return
            commit('SET_NAVBAR_LOADING_UPDATE', true);
            const response = await NavBarService.updateAll();
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            await dispatch('fetchItensNavbar', {keyword: ""} );
            commit('SET_NAVBAR_LOADING_UPDATE', false);
        } catch (error) {
            commit('SET_NAVBAR_LOADING_UPDATE', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async deleteItemNavbar({commit, state}){
        try {
            commit('SET_NAVBAR_LOADING', true);
            const response = await NavBarService.delete(state.itemNavbar.id);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('DELETE_NAVBAR_ITEM', state.itemNavbar.id);
            commit('SET_SHOW_MODAL_CONFIRMATION', false);
            commit('SET_NAVBAR_LOADING', false);
        } catch (error) {
            commit('SET_NAVBAR_LOADING', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getItemNavBar: state => state.itemNavbar,
    getItensNavBar: state => state.itensNavbar,
    getNavbarLoading: state => state.navbarLoading,
    getLoadingUpdateNavbar: state => state.loadingUpdateNavbar,
}

export default {
    state,
    getters,
    mutations,
    actions
};