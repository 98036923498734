import Api from './Api'

export default {
    get(keyword, pageNumber, sort) {
        return Api().get(`logs?keyword=${keyword}&pageNumber=${pageNumber}&sort=${sort}`);
    },
    getTotal() {
        return Api().get(`logs/total`);
    },
    getTotalPendentes() {
        return Api().get(`logs/total-pendentes`);
    },
    getOne(id) {
        return Api().get(`logs/${id}`);
    },
    updateStatus(modification) {
        return Api().patch(`logs/update-status`, modification);
    }
}