import CategoryService from '../../services/CategoryService'

const state = () => ({
    category: null,
    categories: localStorage.getItem('categories') ? JSON.parse(localStorage.getItem('categories')) : [],
    categoriesLoading: false,
    total: 0,
    pages: 0,
    page: 1,
    loadingUpdateCategorires: false
})
  
const mutations = {
    SET_CATEGORY: (state, payload) => {
        state.category = payload;
    },
    SET_CATEGORIES: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.categories = payload.items
        }else{
            state.categories = [...state.categories, ...payload.items]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total
    },
    SET_CATEGORIES_LOADING: (state, payload) => {
        state.categoriesLoading = payload;
    },
    SET_CATEGORIES_LOADING_UPDATE: (state, payload) => {
        state.loadingUpdateCategorires = payload;
    }
}

const actions = {
    async fetchCategories({commit}, { keyword = '', pageNumber = 1}){
        try {
            commit('SET_CATEGORIES_LOADING', true);
            const response = await CategoryService.get(keyword, pageNumber );
            commit('SET_CATEGORIES', response.data);
            commit('SET_CATEGORIES_LOADING', false);
        } catch (error) {
            commit('SET_CATEGORIES_LOADING', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchCategory({commit}, slug){
        try {
            commit('SET_CATEGORIES_LOADING', true);
            const response = await CategoryService.getOne(slug);
            commit('SET_CATEGORY', response.data);
            commit('SET_CATEGORIES_LOADING', false);
        } catch (error) {
            commit('SET_CATEGORIES_LOADING', false);
            // commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updateCategories({commit, dispatch, state}){
        try {
            if(state.loadingUpdateCategorires) return
            commit('SET_CATEGORIES_LOADING_UPDATE', true);
            const response = await CategoryService.updateAll();
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            await dispatch('fetchCategories', {keyword: ""} );
            commit('SET_CATEGORIES_LOADING_UPDATE', false);
        } catch (error) {
            commit('SET_CATEGORIES_LOADING_UPDATE', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getCategory: state => state.category,
    getCategoies: state => state.categories,
    getCategoiesLoading: state => state.categoriesLoading,
    getLoadingUpdateCategorires: state => state.loadingUpdateCategorires,
}

export default {
    state,
    getters,
    mutations,
    actions
};