import AuthService from '../../services/AuthService'
import router from '../../router'

const state = () => ({
    isAuthenticated: false,
    loadingUser: false,
    redirect: '',
    token: localStorage.getItem('token') || null,
    user: (() => {
        try {
            return JSON.parse(localStorage.getItem('user')) || null;
        } catch (error) {
            localStorage.removeItem('user');
            return null;
        }
    })(),
    users: [],
    totalUsers: null,
    totalUsersPendentes: null,
    showModalUser: false,
    new_user: null
})
  
const mutations = {
    REDIRECT: (state, payload) => {
        state.redirect = payload;
    },
    SET_USER: (state, payload) => {
        localStorage.setItem('token', payload.token)
        localStorage.setItem('user', JSON.stringify(payload.user));
        
        state.isAuthenticated = true;
        state.token = payload.token
        state.user = payload.user
        if(router.currentRoute.value.path === '/auth' && !state.redirect){
            router.push({path: `/`});
        }else {
            router.push({path: state.redirect});
            state.redirect = null;
        }
    },
    AUTH_USER: (state, payload) => {
        localStorage.setItem('user', JSON.stringify(payload.user));
        state.user = payload.user;
    },
    SET_LOADING_USER: (state, payload) => {
        state.loadingUser = payload
    },
    SET_SHOW_MODAL_USERS:  (state, payload) => {
        state.showModalUser = payload
    },
    SET_USERS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.users = payload.itens
        }else{
            state.users = [...state.users, ...payload.itens]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total
    },
    SET_NEW_USER: (state, payload) => {
        state.new_user = payload
    },
    SET_TOTAL_USERS: (state, payload) => {
        state.totalUsers = payload
    },
    SET_TOTAL_PENDENTES_USERS: (state, payload) => {
        state.totalUsersPendentes = payload.pendencies
    },
    LOG_USER_OUT(state){
        localStorage.removeItem('user');
        localStorage.removeItem('token');

        state.orders = [];
        state.user = null;
        state.token = null;
        state.currentSteps = [];
        state.isAuthenticated = false;

        if(router.currentRoute.path !== '/auth'){
            router.push({path: `/auth`});
        }
    }  
}

const actions = {
    async loginUser({commit}, credentials ){
        try {
            commit('SET_LOADING_USER', true);
            const response = await AuthService.login(credentials);
            commit('SET_USER', response.data);
            commit('SET_LOADING_USER', false);
        } catch (error) {
            commit('SET_LOADING_USER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchUser({commit}){
        try{
            commit('SET_LOADING_USER', true)
            const response = await AuthService.getUser();
            commit('AUTH_USER', response.data)
            commit('SET_LOADING_USER', false)
        } catch (error) {
            commit('SET_LOADING_USER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchUsers({commit}, { keyword = '', pageNumber = 1, sortBy = 'mais recentes' }){
        try{
            commit('SET_LOADING_USER', true)
            const response = await AuthService.get(keyword, pageNumber, sortBy);
            commit('SET_USERS', response.data)
            commit('SET_LOADING_USER', false)
        } catch (error) {
            commit('SET_LOADING_USER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchOneUser({commit}, idUser){
        try{
            commit('SET_LOADING_USER', true)
            const response = await AuthService.getNewUser(idUser);
            commit('SET_NEW_USER', response.data)
            commit('SET_LOADING_USER', false)
        } catch (error) {
            commit('SET_LOADING_USER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchTotalUsers({commit}){
        try{
            commit('SET_LOADING_USER', true)
            const response = await AuthService.getTotal();
            commit('SET_TOTAL_USERS', response.data)
            commit('SET_LOADING_USER', false)
        } catch (error) {
            commit('SET_LOADING_USER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchTotalPendentesUsers({commit}){
        try{
            const response = await AuthService.getTotalPendentes();
            commit('SET_TOTAL_PENDENTES_USERS', response.data)
        } catch (error) {
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async changeStatusUser({ commit, state, dispatch}, {user, new_status}){
        try{
            if(state.loadingUser) return
            commit('SET_LOADING_USER', true)
            await AuthService.setNewStatus(user, new_status);
            dispatch('fetchUsers', {  keyword: '' }, { root: true });
            commit('SET_LOADING_USER', false)
        } catch (error) {
            commit('SET_LOADING_USER', false)
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async downloadDocument({commit}, fileName){
        try{
            const response = await AuthService.downloadFile(fileName);
            // Crie um URL temporário para o arquivo PDF
            const url = window.URL.createObjectURL(new Blob([response.data]));
            // Crie um link e clique nele para iniciar o download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

            // Libere o URL temporário
            window.URL.revokeObjectURL(url);
                
        } catch (error) {
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    }
}

const getters = {
    getUser: state => state.user,
    getUsers: state => state.users,
    getLoadingUser: state => state.loadingUser,
    getTotalUsers: state => state.totalUsers,
    getTotalUsersPendentes: state => state.totalUsersPendentes,
    getShowModalUser: state => state.showModalUser,
    getNewUser: state => state.new_user
}

export default {
    state,
    getters,
    mutations,
    actions
};