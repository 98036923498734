import Api from './Api'

export default {
    get(keyword, pageNumber, sort) {
        return Api().get(`users?keyword=${keyword}&pageNumber=${pageNumber}&sort=${sort}`);
    },
    login(credentials) {
        return Api().post('users/login-adm', credentials);
    },
    getNewUser(id) {
        return Api().post('users/getNewUser', id);
    },
    getUser(){
        return Api().get('users/profile');
    },
    getTotal() {
        return Api().get(`users/total`);
    },
    getTotalPendentes() {
        return Api().get(`users/total-pendentes`);
    },
    downloadFile(fileName) {
        return Api().post(`users/download-pdf`, {fileName}, { responseType: 'blob' });
    },
    setNewStatus(user, new_status) {
        return Api().post('users/setNewStatus', {user, new_status});
    },
}